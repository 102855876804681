.spin {
  visibility: visible;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  max-width: 451px;
  max-height: 451px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  text-decoration: none;
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
  backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px);
  outline: none;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}